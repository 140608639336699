import $ from 'jquery';
import '../custom/fonts';
import '../custom/lodash';
import '../custom/bootstrap';
// import { waitFor } from '../custom/wait_for';
import * as IC from '../custom/in_control';

window.waitFor(
  function() {
    jQuery(function() {
      if($(document.body).is('.reservations-meeting_reservations')) {
        console.log('[REQUIRE] reservations/meeting_reservations');
        require('../reservations/meeting_reservations');
      }

      IC.icPageInitialize();
    });
  },
  'jquery',
  'lodash'
);
